.Container {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.5em;
    text-align: center;
    margin-bottom: 2em;
    color: #fff;
}
.bodyContainer {
    box-sizing: border-box;
    width: 100%;
    height: 300;
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: #E0E0E0;
}
.HomeContainer {
    width:100%;
    min-width: 600;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 5em;
}
.aboutUsContent {
    font-weight: 500;
    font-variant: initial;
}
.secondContainer {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1em;
    text-align: center;
}
.navBar {
    width: 1000px;
    height: 3em;
    background-color: black;
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    padding: 1em;
    padding-bottom: 3em;
    text-transform: none;
}
.navBarButton {
    margin: auto;
    border-spacing: 1em;
    width: 20%;
    color: white;
}
.navBarButton:hover {
    background-color: #00BFE8;
}
.secondTitle {
    color:white;
}
.image {
    width: '100%';
    max-width: 220px;
    height: auto;
    margin: 40;
}
.imageDash {
    width: '100%';
    max-width: 240px;
    height: auto;
    margin: 40;
}